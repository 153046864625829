// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

// clearfix
%clearfix {
 &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}


// transition
%transition {
 transition: all 0.3s ease-in-out;
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

%underline {
  position: relative;

  .active &,
  &:hover {

    &:before {
      left: 0;
      right: 0;
      visibility: visible;
      opacity: 1;
    }
  }

  &:before {
    @include animate(visibility left right);
    content: "";
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 0;
    background-color: currentColor;
    height: 1px;
    visibility: hidden;
    opacity: 0;
  }
}


