$breakpoints: ('xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators

@import 'vendors/include-media';
@import 'vendors/normalize';
@import 'vendors/fonts';


@import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
// @import 'base/reset';
// @import 'base/typography';
// @import 'base/forms';

@import 'layout/grid';



a {
  @include animate(opacity background color);
}


img {
  max-width: 100%;
  height: auto;
}


.ant-layout-content {
  h1 {
    margin: 0;
  }
}

.ant-upload-list-item-name {
  width: 100%;
  overflow: hidden !important;
  white-space: inherit !important;
  display: -webkit-box !important;
  line-height: 1.2em !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ant-tabs-nav {
  .ant-tabs-tab-active {
    color: #333333;
  }

  .ant-tabs-tab {

    a {
      color: currentColor;
    }
  }
}

.ant-form-item-label,
.ant-legacy-form-item-label {
  >label {
    font-size: 12px;

    @include media('>=tablet') {
      font-size: 14px;
    }
  }
}

.ant-layout-footer {
  display: none;
  padding: 5px 20px 20px;

  @include media('>=tablet') {
    display: block;
  }
}

.ant-form-item,
.ant-legacy-form-item {
  margin-bottom: 10px;

  @include media('<tablet') {
    margin-bottom: 5px;
  }

  .ant-form-item-label {
    @include media('<tablet') {
      padding: 0 0 4px;
    }
  }
}

.ant-input {

  &-disabled {
    color: $black !important;
  }
}


.file-upload {
  .ant-upload-list-item-thumbnail {
    img {
      object-fit: contain;
    }
  }


  .ant-upload-list-picture-card-container {
    +.ant-upload-select-picture-card {
      display: none !important;
    }
  }
}

.title-indent {
  padding: 12px 0;
}

.wrap-link-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  >a,
  button {
    margin: 0 5px !important;

    &:before {
      display: none;
    }
  }
}

.grid-col-2-upload {
  .ant-form-item-control-input-content {
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container {
    width: 100%;
    height: 100px;
    margin: 0;

    @include media('>=tablet') {
      height: 250px;
    }

    @include media('>=1600px') {
      height: 400px;
    }
  }
}


.file-upload-box {

  &.remove-btn-hidden {
    .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
      display: none;
    }
  }
}

.grid-col-2-upload-visible-btn {
  display: block;

  .ant-upload-list-picture-card-container {
    +.ant-upload-select-picture-card {
      display: block !important;
    }
  }

  .ant-upload-list-picture-card {
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    &:before,
    &:after {
      display: none;
    }
  }

  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container {
    width: 100%;
    height: 100px;
    margin: 0;

    @include media('>=tablet') {
      height: 250px;
    }

    @include media('>=1600px') {
      height: 400px;
    }
  }

  &.card-list {
    padding-bottom: 200px;

    .ant-form-item-control-input-content {
      width: 100%;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .ant-upload-list-picture-card-container {
      +.ant-upload.ant-upload-select.ant-upload-select-picture-card {
        display: none !important;
      }
    }

    &.upload-btn-none {

      .ant-upload-picture-card-wrapper.file-upload {
        &:last-child {

          .ant-upload.ant-upload-select.ant-upload-select-picture-card {
            display: none !important;
          }
        }
      }
    }

    .ant-upload-picture-card-wrapper.file-upload {
      &:last-child {

        .ant-upload.ant-upload-select.ant-upload-select-picture-card {
          display: block !important;
          position: absolute;
          top: 100%;
          width: 100%;
          right: 0;
          height: 200px;
          margin: 10px 0;
        }
      }
    }
  }

  &.remove-btn-hidden {
    .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
      display: none;
    }
  }
}

.search-holder {
  .ant-btn {
    @extend .ml-auto;
  }
}

.ml-auto {
  margin-left: auto;
}


.text-row {
  width: 100%;
  overflow: hidden;
  white-space: inherit;
  display: -webkit-box;
  line-height: 1.2em;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &.two {
    -webkit-line-clamp: 2;
  }

  &.three {
    -webkit-line-clamp: 3;
  }
}


.table-header {
  display: flex;
  align-items: center;
  margin: 0 0 10px;

  h1 {
    display: none;
    margin-bottom: 0;

    @include media('>=tablet') {
      display: block;
    }
  }
}

.ant-table {
  &-small {
    font-size: 12px !important;
  }
}

.ant-tabs-nav {
  .ant-tabs-tab {
    font-size: 12px;

    @include media('>=tablet') {
      font-size: 14px;
    }
  }
}


.text-decorator {
  margin: 15px 0 15px;
  display: block;

  * {
    color: currentColor;
  }

  .separator {
    height: 1px;
    background: currentColor;
  }
}

.company-icon-color {
  color: #EB008B;
}

.inner-link {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .5px;
  color: #000;
}

.page-holder {
  overflow: auto;
  flex: auto;
  min-height: 0;
  padding: 10px;

  @include media('>=tablet') {
    padding: 20px;
  }
}

.bg-white {
  background-color: #fff;
}


.block-loader {
  display: flex;
  justify-content: center;
  align-items: center;

  &.h-100 {
    height: 100%;
  }

  &.h-100vh {
    height: 100vh;
  }

  &.h-100px {
    height: 100px;
  }

  &.h-200px {
    height: 200px;
  }

  &.h-300px {
    height: 300px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: auto;
  padding: 20px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, .1);

  @include media('>=tablet') {
    padding: 20px 30px;
  }
}

.form-container {
  width: 100%;

  &.sm {
    max-width: 400px;
  }

  &.md {
    max-width: 600px;
  }

  &.lg {
    max-width: 800px;
  }
}


.ant-notification-notice-with-icon {
  .ant-notification-notice-icon {
    position: static;
    font-size: 28px;
    margin: 4px 13px;
    float: left;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-left: 9px solid;
    }
  }

  .ant-notification-notice-icon-success {
    &::after {
      border-color: #47c65e;
    }
  }

  .ant-notification-notice-icon-error {
    &::after {
      border-color: #FD5F5F;
    }
  }

  .ant-notification-notice-icon-warning {
    &::after {
      border-color: #FFD800;
    }
  }

  .ant-notification-notice-message {
    display: block;
    overflow: hidden;
    font-size: 14px;
    margin-left: 0;
    margin-bottom: 0;
  }

  .ant-notification-notice-description {
    display: block;
    overflow: hidden;
    margin-left: 0;
  }
}

.ant-notification-notice-close {
  position: absolute;
  top: 8px;
  right: 9px;
}

.ant-notification-close-icon {
  font-size: 18px;
}

.ant-notification-notice {
  position: relative;
  padding: 13px 10px;
  border-radius: 6px;
}

.main-table {
  overflow: auto;

  .ant-table-small {
    width: 1250px;
  }

  .ant-avatar-square {
    // @include media('<tablet') {
    //   height: 40px !important;
    // }

    img {
      object-fit: contain;
    }
  }

  .ant-table-cell {
    a {
      @extend %underline;

      &:before {
        @include animate(visibility left right);
        bottom: -2px;
        height: 1px;
      }
    }
  }
}


.holder-table {
  margin: 0 -10px;

  @include media('>=tablet') {
    margin: 0 -20px;
  }

  .ant-table-pagination {
    padding: 0 20px;
  }
}

.table-avatar-link {
  a {
    &::before {
      display: none;
    }
  }
}

.column-description {
  @include media('>=tablet') {
    width: 340px;
    max-width: 340px;
  }

  * {
    overflow: hidden;
    white-space: inherit;
    display: -webkit-box;
    word-break: break-word;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.column-description-core {
  @include media('>=tablet') {
    width: 340px;
    max-width: 340px;
    word-break: break-word;
    overflow: hidden;
  }
}

.column-date {
  width: 130px;
  max-width: 130px;
}


.column-child {
  display: flex;
  justify-content: space-around;

  >a {
    &:before {
      display: none;
    }
  }
}

.ant-form {
  h6 {
    font-size: 14px;
    font-weight: 500;
  }
}

.form-link {
  @extend %underline;
}

.form-group {
  margin-bottom: 0;

  >.ant-form-item-control {
    >.ant-form-item-control-input {
      >.ant-form-item-control-input-content {
        @include media('>=desktop') {
          display: grid;
          column-gap: 8px;
          // grid-template-columns: repeat(2, 1fr);
          grid-template-columns: repeat(2, 390px);
        }
      }
    }
  }


  &.col-two {
    >.ant-form-item-control {
      >.ant-form-item-control-input {
        >.ant-form-item-control-input-content {
          @include media('>=desktop') {
            grid-template-columns: 1fr;
          }

          @include media('>=1400px') {
            // grid-template-columns: repeat(2, 1fr);
            grid-template-columns: repeat(2, 390px);
          }
        }
      }
    }
  }

  &.col-three {
    >.ant-form-item-control {
      >.ant-form-item-control-input {
        >.ant-form-item-control-input-content {
          @include media('>=tablet') {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
    }
  }
}


.select-dropdown-space-between {
  .ant-select-item-option-content {
    // display: flex;
    justify-content: space-between;
  }
}

.select-input-space-between {
  .ant-select-selection-item {
    // display: flex;
    justify-content: space-between;

    &:after {
      display: none !important;
    }
  }
}

.search-holder {
  margin-left: 10px;
}

.ant-radio-button-wrapper-checked {

  &.ant-radio-button-wrapper-disabled {
    color: #1890ff !important;
    background: #fff !important;
    border-color: #1890ff !important;
    z-index: 1;
  }
}

.radio-row {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }
}



.title-box {
  text-transform: capitalize;
}

.tabs-form {
  background-color: $white;

  .ant-tabs-content {
    min-height: 500px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .avatar-data.profile {
    .avatar-label {
      font-size: 18px;
    }
  }
}

.text-align-left {
  text-align: left !important;
}

body .text-align-left-md {

  @include media('>=tablet') {
    text-align: left !important;
  }
}

.text-align-center {
  text-align: center !important;
}

body .text-align-center-md {

  @include media('>=tablet') {
    text-align: center !important;
  }
}

.image-page-cutomization,
.showroom-image {


  &.no-height {
    .avatar-image {

      &.with-image {

        height: auto;

        @include media('>=tablet') {

          height: auto;
        }
      }
    }
  }

  .ant-avatar {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }

  .ant-upload {
    display: block !important;

    @include media('>=tablet') {
      display: flex !important;

    }
  }

  .avatar-image {

    &.with-image {
      width: 100%;
      height: 200px;
      background-color: rgba(black, .2);

      @include media('>=widescreen') {
        width: calc(100% - 300px);
        height: 380px;
      }
    }

    &.no-image {
      width: 100%;
      height: 200px;
      max-width: 600px;

      @include media('>=widescreen') {
        height: 300px;
      }
    }
  }



  .ant-btn.ant-btn-default {

    @include media('<widescreen') {
      margin: 20px 0;
    }
  }

  .photo-upload {
    svg {
      font-size: 220px;

      @include media('>=widescreen') {
        font-size: 300px;
      }
    }
  }
}

.custom-drop-filter {
  padding: 10px 20px;
  width: 300px;

  .ant-row {
    display: block;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-select-selection-item {
  display: block;

  @include media('>=desktop') {
    max-width: 98%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

.text-overflow-ellipsis {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}


.download-file-info {
  min-width: 1px;
  flex: 1 1;

  .download-file-name {
    display: flex;
    align-items: center;
    flex: 1 1;

    .file-name {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}