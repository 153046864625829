@import '../../../../scss/vendors/include-media';
@import '../../../../scss/base/variables';



.form-theme {
  max-width: 900px;

  .row-grid {
    .avatar-image {

      &.with-image {
        width: 100%;
        height: 200px !important;

        @include media('>=tablet') {
          width: 100%;
          height: 200px !important;
        }
      }

    }
  }

  // .ant-btn {
  //   box-shadow: 0 0 5px rgba(0, 0, 0, .2);
  // }
}