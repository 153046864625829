@import '../../scss/vendors/include-media';
@import '../../scss/base/variables';

$breakpoints: ('xs-phone': 320px,
	'phone': 480px,
	'tablet': 768px,
	'desktop': 1024px,
	'widescreen': 1200px);

.nav-open {
	overflow: hidden;

	@include media('>=992px') {
		overflow: visible;
	}

	.main-section {
		position: relative;
		z-index: 0;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, .5);
			z-index: 11;

			@include media('>=992px') {
				background-color: transparent;
				z-index: -1;
			}
		}
	}
}

.main-layout {
	display: flex;
	flex-direction: row !important;

	.anticon-menu-fold,
	.anticon-menu-unfold {
		display: flex;
		font-size: 20px;

		@include media('>=992px') {
			visibility: hidden;
		}
	}
}

.main-breadcrumb {


	&.ant-breadcrumb {
		text-transform: uppercase;
		padding: 20px 20px 0;
		font-size: 10px;
		letter-spacing: .5px;
		font-weight: 500;

		@include media('>=tablet') {
			padding: 25px 40px 0;
		}

		>span:last-child a {
			cursor: text;
		}
	}
}


.ant-layout {
	&.main-section {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		min-height: 100vh;
		background: $main-bg-color;
	}
}



.main-content {
	display: flex;
	margin: 20px 0;

	@include media('>=tablet') {
		margin: 20px;
	}
}

.logo {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 55px;
	margin: 20px 0;

	img {
		width: auto;
		max-width: 100%;
		max-height: 100%;
	}
}