@import '../../scss/vendors/include-media';

.authorization-page {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	// background-color: rgba(27, 34, 35, 0.95);

	@include media('>=tablet') {
		background-color: #CCC;
	}

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		background-size: cover;
		opacity: 0.6;
	}

	.form-holder {
		font: 14px/1em Open Sans, sans-serif;
		width: 400px;
		z-index: 1;
		border-radius: 20px;

		@include media('>=tablet') {
			background-color: white;
			padding: 30px 20px;
		}

		h2 {
			font: 18px/1.3em Open Sans, sans-serif;
			text-align: center;
			margin-bottom: 25px;
		}

		form {
			width: 280px;
			margin: 0 auto;
		}

		.form-icon {
			color: rgba(0, 0, 0, .45);
		}
	}

	.ant-form-item-control {
		line-height: 1em;
	}

	.ant-form-item {
		margin-bottom: 22px;

		&:last-child {
			padding-top: 5px;
			margin-bottom: 0;

			.ant-form-item-children {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	.ant-form-item-with-help {
		margin-bottom: 5px;
	}

	.ant-form-explain {
		font-size: 13px;
		line-height: 1.4em;
		min-height: 15px;
		margin-top: 0;
	}

	.logo {
		font: 600 40px/1em "Noto Serif", serif;
		color: #209cee;
		text-align: center;
		margin-bottom: 25px;
	}

	.ant-input {
		font: 14px/1em Open Sans, sans-serif;
		color: #000;
	}

	.divider {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 30px auto;
		width: 280px;

		>* {
			flex-grow: 1;
			flex-shrink: 1;

			&:nth-of-type(2) {
				padding: 0 20px;
				flex-grow: 0;
				flex-shrink: 0;
			}
		}

		* {
			&:first-child {
				&:before {
					content: "";
					width: 100%;
					height: 1px;
					background-color: #FFF;
					display: block;
				}
			}

			&:last-child {
				&:before {
					content: "";
					width: 100%;
					height: 1px;
					background-color: #FFF;
					display: block;
				}
			}
		}
	}

	.sign-link {
		color: #209cee;
		display: block;

		&:hover {
			color: #ffffff;
		}
	}

	.sign-link-wrap {
		text-align: center;

		p {
			margin-bottom: 5px;
			color: #CCCCCC;
		}
	}

	.logo-img {
		text-align: center;
		margin-bottom: 30px;
	}
}