@import '../../../scss/vendors/include-media';

.color-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px;

    .color-group {
        width: calc(100% / 10 * 2 - 10px);
        list-style: none;
        padding: 0;
        margin: 0 5px;

        @include media('>=tablet') {
            width: calc(100% / 10 * 1 - 10px);
        }

        li {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;

            .color-select-button {
                width: 50px;
                min-width: 50px;
                height: 50px;

                @include media('>=tablet') {
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                }
            }

            &.current {
                .color-select-button {
                    font-size: 46px;

                    @include media('>=tablet') {
                        font-size: 30px;
                    }
                }
            }
        }

        .color-select-button {
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            width: 100%;
            height: 100%;
            padding: 0;
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            font-size: 0;
            color: var(--base-color);
        }
    }
}