.account-form {
  // max-width: 700px;

  .ant-select-selection-item {
    // display: flex;
    justify-content: space-between;

    &:after {
      display: none !important;
    }
  }
}

.select-event-dropdown {
  .ant-select-item-option-content {
    // display: flex;
    justify-content: space-between;
  }
}