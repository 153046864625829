.authorization-page {
	.logo-img {
		width: 160px;
		margin: 0 auto;
	}
}

.login-form {
	.ant-input-affix-wrapper {
		.ant-input {
			&:not(:first-child) {
				padding-left: 15px;
			}
		}
	}

	.ant-legacy-form-item-children {
		display: flex;
		justify-content: space-between;
	}

	.ant-input {
		padding: 0 20px;
	}
}