@import '../../../../scss/vendors/include-media';

.translations-form {
  max-width: 400px;
}

.count-box {
  display: flex;
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin: 0;

  @include media('>=tablet') {
    display: block;
    font-size: 14px;
    margin-top: -10px;
    padding: 0 10px;
  }

  li {
    &:last-child {

      @include media('>=tablet') {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        margin-top: 8px;
      }

      strong {
        display: block;
        margin-left: 10px;

        @include media('>=tablet') {
          font-size: 16px;
          margin-left: 0;
        }
      }
    }
  }
}