@import '../../scss/vendors/include-media';
@import '../../scss/base/variables';

$breakpoints: ('xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px);


.ant-layout-sider {
  &.main-nav {
    position: fixed !important;
    height: 100%;
    padding: 10px 0 50px;
    overflow: auto;
    background: $gray-light;
    z-index: 2;

    @include media('<992px') {
      flex: 0 0 250px !important;
      max-width: 250px !important;
      min-width: 250px !important;
      width: 250px !important;
      transform: translateX(-100%);
    }

    .main-menu {
      background: $gray-light;

      .ant-menu-sub {
        .ant-menu-item {
          font-size: 13px !important;
          margin: 0 !important;
        }
      }
    }

    .anticon-menu-fold,
    .anticon-menu-unfold {
      // color: white;
      justify-content: flex-end;
      padding: 10px;

      @include media('>=992px') {
        display: none;
      }
    }

    .nav-open & {

      @include media('<992px') {
        transform: translateX(0);
      }
    }


    @include media('>=992px') {
      position: relative !important;
      height: 100vh;
    }
  }
}