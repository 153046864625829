@import '../../../../scss/vendors/include-media';

$breakpoints: (
  'xs-phone': 320px,
  'phone': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px
);

.tooltip-width {
  @include media('>=tablet') {
    min-width: 600px;
  }
}

