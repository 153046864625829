@import '../../scss/base/variables';

.user-dropdown {
  // float: right;
  display: flex;
  align-items: center;
  color: $base-text-color;
  height: 30px;

  &:hover {
    color: $base-text-color;
  }

  .box-img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;
    height: 30px;
    border-radius: 50%;

    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .anticon-down {
    font-size: 10px;
  }
}