// font-weight helper:
// 100 Extra Light or Ultra Light;
// 200 Light or Thin; 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black
@mixin font($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
		font-family: $f-name;
		font-style: $f-style;
    font-weight: $f-weight;
    font-display: swap;
    src: url($f-url) format('woff2');
  }
}


@include font('Roboto', normal, 400, '../fonts/roboto-regular-webfont.woff2');
@include font('Roboto', normal, 500, '../fonts/roboto-medium-webfont.woff2');
@include font('Roboto', italic, 400, '../fonts/roboto-italic-webfont.woff2');
@include font('Roboto', normal, 700, '../fonts/roboto-bold-webfont.woff2');
@include font('Roboto', italic, 700, '../fonts/roboto-bolditalic-webfont.woff2');
