@import '../../../scss/vendors/include-media';
@import '../../../scss/base/variables';


.interface-block {
  padding: 0 150px 0 0;
}

.upload-file-list-hidden {
  .ant-upload-list {
    display: none !important;
  }
}